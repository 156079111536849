import type { ReactNode } from 'react'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Link,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import Grid from '@mui/material/Grid2'

import { useTranslations } from 'next-intl'

export type OperationType = {
  translationsKey: string
  onClick: () => void
  cardActionComponent?: ReactNode
}

type Props = {
  title: string
  operationTypes: OperationType[]
}

function OperationListItem(chunks: ReactNode): JSX.Element {
  return <li>{chunks}</li>
}

function Quote(chunks: ReactNode): JSX.Element {
  const theme = useTheme()
  return (
    <Link
      color={theme.palette.info.dark}
      href="/info/products/legal-policy#quote-card"
      target="_blank"
    >
      {chunks}
    </Link>
  )
}

export function NewOperationsSection({
  title,
  operationTypes,
}: Readonly<Props>): JSX.Element {
  const theme = useTheme()
  const t = useTranslations(`NewOperation`)

  return (
    <Box width="100%">
      <Typography
        variant="subtitle1"
        sx={{ mb: '7px' }}
        data-intercom-target="PurchaseProduct"
      >
        {title}
      </Typography>
      <Grid container spacing={2} columns={12}>
        {operationTypes.map((operationType) => {
          return (
            <Grid size={{ xs: 12, md: 4 }} key={operationType.translationsKey}>
              <Card
                sx={{
                  border: `1px solid ${theme.palette.primary.main}`,
                  height: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  width: 1,
                }}
                data-testid={`operation-card-${operationType.translationsKey}`}
              >
                <CardContent sx={{ paddingBottom: 0 }}>
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Typography variant="body1">
                      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment -- keys come from backend */}
                      {/* @ts-ignore -- Keys comes from backend */}
                      {t(`${operationType.translationsKey}.Title`)}
                    </Typography>
                    <Button
                      variant="text"
                      onClick={operationType.onClick}
                      size="small"
                      component="a"
                      sx={{ padding: 0 }}
                    >
                      {t('Purchase')}
                    </Button>
                  </Stack>
                  <Typography
                    variant="body2"
                    color={theme.palette.text.secondary}
                    gutterBottom
                  >
                    <ul>
                      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment -- keys come from backend */}
                      {/* @ts-ignore -- Keys comes from backend */}
                      {t.rich(`${operationType.translationsKey}.List`, {
                        li: OperationListItem,
                      })}
                    </ul>
                  </Typography>
                </CardContent>
                <Box flexGrow={1} />
                <CardActions sx={{ paddingLeft: 2, paddingTop: 0 }}>
                  <Typography variant="body2" color={theme.palette.info.dark}>
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment -- keys come from backend */}
                    {/* @ts-ignore -- Keys comes from backend */}
                    {t.rich(`${operationType.translationsKey}.Cost`, {
                      quote: Quote,
                    })}
                  </Typography>
                </CardActions>
              </Card>
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}
