import { useTheme } from '@mui/material'

// Define the type for the getColor function
type GetColorFunction = (score: number) => string

export function useScoreMainColor(): GetColorFunction {
  const theme = useTheme()

  const getColor: GetColorFunction = (score: number): string => {
    if (score < 2.4) {
      return theme.palette.error.main
    }
    if (score < 3.5) {
      return theme.palette.warning.main
    }
    return theme.palette.primary.main
  }

  return getColor
}
