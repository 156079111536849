import type { ReactElement } from 'react'
import type { SvgIconProps } from '@mui/material'
import { useTheme } from '@mui/material'

type Props = {
  color?: string
  width?: number | string
  height?: number | string
}

function Agent2Icon({
  color,
  width = '33',
  height = '33',
  ...props
}: Readonly<Props>): ReactElement<SvgIconProps> {
  // if color is undefined, get the primary main from useTheme
  const theme = useTheme()
  const iconColor = color ?? theme.palette.secondary.main
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${String(width)} ${String(height)}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="1" y="1" width="31" height="31" rx="15.5" fill="white" />
      <rect x="1" y="1" width="31" height="31" rx="15.5" stroke={color} />
      <path
        d="M26 11V18.5H24V12L19 8.5L14 12V13.5H12V11L19 6L26 11ZM20.5 11.5H19.5V12.5H20.5V11.5ZM18.5 11.5H17.5V12.5H18.5V11.5ZM20.5 13.5H19.5V14.5H20.5V13.5ZM18.5 13.5H17.5V14.5H18.5V13.5ZM24 20.5H22C22 19.3 21.25 18.22 20.13 17.8L13.97 15.5H6V26.5H12V25.06L19 27L27 24.5V23.5C27 21.84 25.66 20.5 24 20.5ZM8 24.5V17.5H10V24.5H8ZM18.97 24.91L12 22.98V17.5H13.61L19.43 19.67C19.77 19.8 20 20.13 20 20.5C20 20.5 18.01 20.45 17.7 20.35L15.32 19.56L14.69 21.46L17.07 22.25C17.58 22.42 18.11 22.51 18.65 22.51H24C24.39 22.51 24.74 22.74 24.9 23.07L18.97 24.91Z"
        fill={iconColor}
      />
    </svg>
  )
}

export default Agent2Icon
