import { Box, Typography, useTheme } from '@mui/material'

type Props = {
  caption: string
  count: number
}

export function SummaryCount({ caption, count }: Readonly<Props>): JSX.Element {
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.info.light,
        width: '147px',
        height: '77px',
        borderRadius: '8px',
      }}
    >
      <Typography variant="caption" color="text.secondary">
        {caption}
      </Typography>
      <Typography variant="h5">{count}</Typography>
    </Box>
  )
}
