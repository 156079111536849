import { useFICOColor } from './useFICOColor'
import { useIsApprover } from './useIsApprover'
import { useIsMobile } from './useIsMobile'
import { useIsTablet } from './useIsTablet'
import { useScoreLightColor } from './useScoreLightColor'
import { useScoreMainColor } from './useScoreMainColor'

export {
  useFICOColor,
  useIsApprover,
  useIsMobile,
  useIsTablet,
  useScoreLightColor,
  useScoreMainColor,
}
