import { useSearchParams } from 'next/navigation'

export function useIsApprover(): boolean {
  const searchParams = useSearchParams()

  if (searchParams.has('isApprover')) {
    return Boolean(searchParams.get('isApprover')?.toLowerCase() === 'true')
  }
  return false
}
