import { useTheme } from '@mui/material'

type GetColorAndOffsetFunction = (
  score: number,
  gapSize?: number,
) => [string, number]

export function useFICOColor(): GetColorAndOffsetFunction {
  const theme = useTheme()

  const getColor: GetColorAndOffsetFunction = (
    score: number,
    gapSize = 0,
  ): [string, number] => {
    if (score < 520) {
      return [theme.palette.error.main, gapSize * 0]
    }
    if (score < 580) {
      return [theme.palette.warning.main, Number(gapSize)]
    }
    if (score < 640) {
      return [theme.palette.secondary.light, gapSize * 2]
    }
    if (score < 740) {
      return [theme.palette.success.main, gapSize * 3]
    }
    return [theme.palette.success.dark, gapSize * 4]
  }
  return getColor
}
