import type { ReactNode } from 'react'
import { ChevronRight, DescriptionOutlined, Search } from '@mui/icons-material'
import StarSharpIcon from '@mui/icons-material/StarSharp'
import {
  Avatar,
  Badge,
  Box,
  Chip,
  Container,
  Divider,
  List,
  ListItemButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'

import { useTranslations } from 'next-intl'

import { useIsMobile } from '../../../hooks/useIsMobile'
import { SummaryCount } from '../../SummaryCount'

type EmptyStateProps = {
  onDemoRentOperationClick: () => void
  onDemoInvestigationClick: () => void
  children: ReactNode
}

function EmptyState({
  onDemoRentOperationClick,
  onDemoInvestigationClick,
  children,
}: Readonly<EmptyStateProps>): JSX.Element {
  const t = useTranslations('Investigations.Pages.ControlPanel')
  const tCommon = useTranslations('UICommon')
  const tList = useTranslations('Investigations.Pages.ControlPanel.List')
  const theme = useTheme()
  const isMobile = useIsMobile()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {children}
      <Container
        sx={{
          background: theme.palette.background.paper,
          borderRadius: 2,
          p: 2,
          mt: 3,
          mb: 6,
          width: '100%',
        }}
      >
        <Typography
          fontWeight={500}
          variant="subtitle2"
          textAlign={isMobile ? 'left' : 'center'}
          sx={{ mb: 2 }}
        >
          {tList('Title')}
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
          <SummaryCount caption={t('List.Active')} count={0} />
          <SummaryCount caption={t('List.Closed')} count={2} />
        </Box>

        <Divider
          sx={{
            mt: 2,
            borderColor: theme.palette.divider,
            width: '100%',
            height: 1,
          }}
        />

        <List sx={{ width: '100%' }}>
          <ListItemButton
            onClick={() => {
              onDemoRentOperationClick()
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              height="100%"
            >
              <Box display="flex" alignItems="center" gap={1}>
                <Badge
                  color="secondary"
                  overlap="circular"
                  badgeContent=" "
                  variant="dot"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  sx={{ maxHeight: '40px' }}
                >
                  <Avatar
                    sx={{
                      backgroundColor: theme.palette.common.white,
                      border: `1px solid ${theme.palette.secondary.light}`,
                    }}
                  >
                    <DescriptionOutlined
                      sx={{
                        color: theme.palette.secondary.light,
                      }}
                    />
                  </Avatar>
                </Badge>
                <Box
                  display="flex"
                  flexDirection="column"
                  flex="1 1 100%"
                  justifyContent="center"
                  alignItems="flex-start"
                  overflow="hidden"
                  width="100%"
                  height="100%"
                >
                  <Chip
                    size="small"
                    icon={<StarSharpIcon color="warning" />}
                    label={
                      <Stack direction="row" spacing={0.5}>
                        <Typography variant="body2" color="primary">
                          Demo:
                        </Typography>
                        <Typography variant="body2">
                          {tCommon('LegalPolicy')}
                        </Typography>
                      </Stack>
                    }
                    sx={{
                      border: `1px solid ${theme.palette.primary.main}`,
                    }}
                    variant="outlined"
                  />
                  <Typography variant="body2" sx={{ mb: 0.25 }}>
                    Prol. Paseo de la Reforma 1000, Col. Cruz Manca Santa Fe,
                    Cuajimalpa, CDMX, C.P. 10810
                  </Typography>
                </Box>
              </Box>
              <ChevronRight sx={{ color: theme.palette.grey[400] }} />
            </Box>
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              onDemoInvestigationClick()
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              height="100%"
            >
              <Box display="flex" alignItems="center" gap={1}>
                <Badge
                  color="secondary"
                  overlap="circular"
                  badgeContent=" "
                  variant="dot"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  sx={{ maxHeight: '40px' }}
                >
                  <Avatar
                    sx={{
                      backgroundColor: theme.palette.common.white,
                      border: `1px solid ${theme.palette.secondary.light}`,
                    }}
                  >
                    <Search
                      sx={{
                        color: theme.palette.secondary.light,
                      }}
                    />
                  </Avatar>
                </Badge>
                <Box
                  display="flex"
                  flexDirection="column"
                  flex="1 1 100%"
                  justifyContent="center"
                  alignItems="flex-start"
                  overflow="hidden"
                  width="100%"
                  height="100%"
                >
                  <Chip
                    size="small"
                    icon={<StarSharpIcon color="warning" />}
                    label={
                      <Stack direction="row" spacing={0.5}>
                        <Typography variant="body2" color="primary">
                          Demo:
                        </Typography>
                        <Typography variant="body2">
                          {tCommon('Investigation')}
                        </Typography>
                      </Stack>
                    }
                    sx={{
                      border: `1px solid ${theme.palette.primary.main}`,
                    }}
                    variant="outlined"
                  />
                  <Typography variant="body2" sx={{ mb: 0.25 }}>
                    Rodrigo Velazco Lazo
                  </Typography>
                </Box>
              </Box>
              <ChevronRight sx={{ color: theme.palette.grey[400] }} />
            </Box>
          </ListItemButton>
        </List>
      </Container>
    </Box>
  )
}

export default EmptyState
