import { Backdrop, CircularProgress } from '@mui/material'

export function FullScreenCircularProgress(): JSX.Element {
  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
      }}
      open
    >
      <CircularProgress color="primary" />
    </Backdrop>
  )
}
