import React from 'react'
import type { SxProps, Theme } from '@mui/material'
import { Box, useTheme } from '@mui/material'

type Props = {
  children: React.ReactNode
  sx?: SxProps<Theme>
}

export function Card({ children, sx }: Props): React.JSX.Element {
  const theme = useTheme()

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.common.white,
        p: 2,
        mt: 3,
        borderRadius: '8px',
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}
