import React, { useMemo } from 'react'
import {
  CancelOutlined,
  CheckCircleOutlined,
  InfoOutlined,
  WarningAmberOutlined,
} from '@mui/icons-material'
import type { SvgIconTypeMap, SxProps, Theme } from '@mui/material'
import { useTheme } from '@mui/material'
import type { OverridableComponent } from '@mui/material/OverridableComponent'

import { Card } from '../Card'

type AlertType = 'success' | 'error' | 'warning' | 'info'

type Props = {
  children: React.ReactNode
  type: AlertType

  Icon?: OverridableComponent<SvgIconTypeMap> & {
    muiName: string
  }
  sx?: SxProps<Theme>
}

const useAlertStyle = (
  type: AlertType,
): { backgroundColor: string; Icon: OverridableComponent<SvgIconTypeMap> } => {
  const theme = useTheme()

  const style = useMemo(() => {
    const alertMap = {
      success: {
        backgroundColor: theme.palette.primary.light,
        Icon: CheckCircleOutlined,
      },
      error: {
        backgroundColor: theme.palette.error.light,
        Icon: CancelOutlined,
      },
      warning: {
        backgroundColor: theme.palette.warning.light,
        Icon: WarningAmberOutlined,
      },
      info: {
        backgroundColor: theme.palette.info.light,
        Icon: InfoOutlined,
      },
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- We want to return an empty object if the type is not found
    return alertMap[type] || alertMap.info
  }, [type, theme])

  return style
}

export function Alert({
  type,
  children,
  Icon: CustomIcon,
  sx,
}: Readonly<Props>): React.JSX.Element {
  const theme = useTheme()
  const { Icon: DefaultIcon, backgroundColor } = useAlertStyle(type)
  const Icon = CustomIcon ?? DefaultIcon

  return (
    <Card
      sx={{
        backgroundColor,
        display: 'flex',
        alignItems: 'center',
        ...sx,
      }}
    >
      <Icon
        sx={{
          color: theme.palette[type].dark,
          mr: 2,
          width: '20px',
          height: '20px',
        }}
      />
      {children}
    </Card>
  )
}
